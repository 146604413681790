import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    pure: true,
    name: 'localizeRoute',
    standalone: true
})
export class LocalizeRoutePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  public transform(value: string): string {
    return '/' + this.translateService.currentLang + (value.length > 1 ? value : '');
  }
}
